$accent: #28f09dff;
$primary: #02a1f9ff;
$ghost-white: #f0eff4ff;
$warning: #f5a623ff;
$success: #4caf50ff;
$danger: #f44336ff;
$info: #2196f3ff;

$navbar-bg: #fff;
$sidenav-bg: #0f172a;
$footer-bg: #fff;
$body-bg: #f1f5f9;

$menu-items: #FFF;
$menu-hover: darken($menu-items, 30%);
$primary-color: $primary;
$app-color: #b2d2f6;