/* You can add global styles to this file, and also import other style files */
@import "bootstrap/scss/bootstrap";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "styles/colors.scss";
$theme-colors: (
	"primary": $primary,
	"secondary": $accent,
	"success": $success,
	"info": $info,
	"warning": $warning,
	"danger": $danger
);

.p-inputnumber,
.p-calendar,
.p-dropdown,
.p-password,
.p-multiselect,
.p-password-input {
	width: 100% !important;
}

.p-dropdown-items-wrapper ul,
.p-multiselect-items-wrapper ul {
	padding-left: 0;
	margin-bottom: 0;
}

body {
	font-family: "Inter", sans-serif !important;
	background: #dbdada;
	overflow-x: hidden;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
  --main-hue: 208;
  --main-color: hsl(var(--main-hue), 92%, 54%);
  --main-color-hover: hsl(var(--main-hue), 77%, 48%);
  --light-main-color: hsla(var(--main-hue), 91%, 55%);
  --text-color: #000;
  --nav-color: hsl(var(--main-hue), 17%, 79%);
  --bg-color: #fff;
  --light-text-color: #9ca7b6;
  --input-bg: hsla(var(--main-hue), 50%, 50%, 6.5%);
  --input-bg-hover: hsla(var(--main-hue), 50%, 50%, 14%);
  --input-text: #8c9aaf;
  --input-shadow: hsla(var(--main-hue), 92%, 54%, 0.169);
  --background-color: #f5f6f9;
  --noselected-menu-item-color: white;
  --navbar-text-color: black;
  --navbar-box-shadow-color: gray;
  --icon-color: #989898;
  --grid-actions-icon-color: #474747;
  --grid-background-color: white;
  --grid-paginator-page-number-highlight-color: #EFF6FF;
  --grid-scrollbar-color: #989898;
  --filter-color: #e3e3e3;
  --filter-text-color: #8b8b8b;
  --active-filter-text-color: #fff;
  --grid-selected-row-color: #ececec;
  --grid-line-color: #e5e7eb;
  --dashboard-calendar-button-color: #e3e3e3;
  --dashboard-calendar-button-text-color: #8b8b8b;
  --dashboard-calendar-today-color: #ededed;
  --text-input-label-color: #474747a1;
  // NEW
  --navigation-logo-background-color: white;
  --side-nav-color: #02a1f9;
  --active-filter-color: #02a1f9;
  --selected-menu-item-color: #02a1f9;
  --tenant-name-color: #02a1f9;
}

.content .card {
	border-radius: 0.5rem;
	padding: 10px;
	& .card-header {
		background: none;
		border-bottom: none;
		font-size: 1.4em;
	}
}

.bg-app {
	background: $app-color !important;
	color: #000;
}

.app-label-required {
	&::after {
		content: " *";
		color: $danger;
	}
}

a {
	text-decoration: none;
}
